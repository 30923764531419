import React from "react"
import Helmet from "react-helmet"
import { Layout } from "../layouts"
import peachBackgroundImg from "../images/peachbackground.png"
import { Flex, Link, Button, useDisclosure } from "@chakra-ui/react"
import { PressModal } from "../components/juicy/press"
import { ContactModal } from "../components/juicy/contact"
import peach from "../images/peach.png"

const CustomButton = ({ children, ...rest }) => {
    return (
        <Button
            variant="ghost"
            fontSize="2xl"
            color="white"
            _hover={{ color: "#FFE5B4" }}
            {...rest}
        >
            {children}
        </Button>
    )
}

const IndexPage = () => {
    const {
        isOpen: isPressModalOpen,
        onOpen: onPressModalOpen,
        onClose: onPressModalClose,
    } = useDisclosure()
    const {
        isOpen: isContactModalOpen,
        onOpen: onContactModalOpen,
        onClose: onContactModalClose,
    } = useDisclosure()

    return (
        <Layout>
            <Helmet
                title="NAOMI VAUGHN | JUICY"
                link={[
                    {
                        rel: "icon",
                        type: "image/png",
                        href: peach,
                        sizes: "16x16",
                    },
                ]}
            />
            <Flex
                position="absolute"
                backgroundImage={peachBackgroundImg}
                backgroundPosition="center"
                backgroundSize="cover"
                height="100dvh"
                width="100dvw"
                fontFamily="Arimo"
            >
                <Flex
                    width={{
                        base: "container.xs",
                        sm: "container.sm",
                        md: "container.md",
                    }}
                    mt="auto"
                    mx="auto"
                    mb="4rem"
                    justifyContent="space-between"
                >
                    <CustomButton onClick={onPressModalOpen}>
                        PRESS
                    </CustomButton>
                    <CustomButton
                        as={Link}
                        href="https://open.spotify.com/track/0IvmW2HJrScnzsMIMlSq7G?si=5b49e7e247324d07"
                        target="_blank"
                    >
                        LISTEN
                    </CustomButton>
                    <CustomButton onClick={onContactModalOpen}>
                        CONTACT
                    </CustomButton>
                </Flex>
                <PressModal
                    isOpen={isPressModalOpen}
                    onClose={onPressModalClose}
                />
                <ContactModal
                    isOpen={isContactModalOpen}
                    onClose={onContactModalClose}
                />
            </Flex>
            {/* <Peach /> */}
        </Layout>
    )
}

export default IndexPage
